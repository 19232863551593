import React, { useState } from 'react'
import fashion_model from "../Media/fashion_model_1.png";
import logo from "../Media/logo.png";
import heading_design_left from "../Media/heading_design_left.png";
import heading_design_right from "../Media/heading_design_right.png";
import { Card, FloatingLabel, Form } from 'react-bootstrap';
import Axios from "axios";

const FashionShowModelRegistration = () => {

    const submitFashionModelRegistration = async (e) => {
        // return;
        e.preventDefault();

        const submitFashionModelData = {
            name: e.target.name.value,
            mobile: e.target.mobile.value,
            email: e.target.email.value,
            brand: e.target.brand.value,
            profile: (e.target.select_fashion_model_profile.options[e.target.select_fashion_model_profile.selectedIndex].text),
            agency_name: (e.target.agency_name != undefined || e.target.agency_name != null) ? (e.target.agency_name.value) : "",
        }

        const submitFashionModelUrl = global.config.server_url + "addDataFashionModelRegistration";

        console.log(submitFashionModelUrl, submitFashionModelData);

        Axios.post(submitFashionModelUrl, submitFashionModelData).then((response) => {
            if (response.data === true) {
                alert("You will receive Invitation through your mail");
                e.target.name.value = "";
                e.target.mobile.value = "";
                e.target.email.value = "";
                e.target.brand.value = "";
                if (e.target.agency_name != undefined || e.target.agency_name != null) {
                    e.target.agency_name.value = "";
                }
                document.getElementById("btn_whatsapp_send_invitation").click();
            } else {
                alert("error in sending data")
            }
        }).catch(err => {
            console.log(err);
            alert("Error in sending data");
        });
    }

    const [fashionModelBrandValue, setFashionModelBrandValue] = useState(false);

    const changeProfile = (e) => {
        var selectedValue = e.target.value;
        console.log(selectedValue);
        if (selectedValue == "Agency") {
            setFashionModelBrandValue(true);
        } else {
            setFashionModelBrandValue(false);
        }
    }

    return (
        <div>
            <section className="section_fashionModelRegistration_banner">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="div_registration_banner_parent">
                                <div className="div_text_on_registration_banner_image">
                                    <h1 className="text_on_registration_banner_image"> SHOWCASE YOUR MODELLING SKILLS AT WEDDING EXPO BY  </h1>
                                    <img src={logo} alt="official wedding expo" className="logo_on_slider" />
                                </div>
                                <img src={fashion_model} className="img_registration_banner" alt="fashionModel registration" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_fashionModel_form">
                <div className="container">
                    <div className="row">
                        <div className="row">
                            <div className="col-12 div_heading">
                                <ul className="nav justify-content-center">
                                    <li className="nav-item my-auto">
                                        <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                    </li>
                                    <li className="nav-item my-auto">
                                        <h1 className="h_tag_heading"> Fill Your Details </h1>
                                    </li>
                                    <li className="nav-item my-auto">
                                        <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <Card className="card_form_registration">
                                <Card.Body className="py-0">
                                    <form onSubmit={(e) => submitFashionModelRegistration(e)}>
                                        <div className="form-group mt-2">
                                            {/* <input type="text" name="name" id="name" className="form-control input_registration" placeholder="Name" /> */}
                                            <FloatingLabel controlId="floatingInputGrid" label="Name">
                                                <Form.Control type="text" name="name" placeholder="Name" required="required" />
                                            </FloatingLabel>
                                        </div>
                                        <div className="form-group mt-2">
                                            {/* <input type="number" name="mobile" id="mobile" className="form-control input_registration" placeholder="Mobile" /> */}
                                            <FloatingLabel controlId="floatingInputGrid" label="Mobile">
                                                <Form.Control type="number" name="mobile" placeholder="Mobile" required="required" />
                                            </FloatingLabel>
                                        </div>
                                        <div className="form-group mt-2">
                                            {/* <input type="email" name="email" id="email" className="form-control input_registration" placeholder="Email" /> */}
                                            <FloatingLabel controlId="floatingInputGrid" label="Email">
                                                <Form.Control type="email" name="email" placeholder="Email" required="required" />
                                            </FloatingLabel>
                                        </div>
                                        <div className="form-group mt-2">
                                            <FloatingLabel controlId="floatingInputGrid" label="Brand Name">
                                                <Form.Control type="text" name="brand" placeholder="Brand Name" required="required" />
                                            </FloatingLabel>
                                        </div>


                                        <div className="form-group mt-2">
                                            <FloatingLabel controlId="floatingSelect" label="Select Profile">
                                                <Form.Select aria-label="SelectProfile" name="select_fashion_model_profile" onChange={(e) => changeProfile(e)}>
                                                    <option value="Individual" selected>Individual</option>
                                                    <option value="Agency">Agency</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>

                                        {(fashionModelBrandValue == true) ? (
                                            <div className="form-group mt-2">
                                                <FloatingLabel controlId="floatingInputGrid" name="" label="Agency Name">
                                                    <Form.Control name="agency_name" id="fashion_model_agency" placeholder="Agency Name" required="required" />
                                                </FloatingLabel>
                                            </div>
                                        ) : ("")}


                                        <div className="form-group mt-3">
                                            <button type="submit" className="btn btn_dhruva d-block mx-auto my-2">Register</button>
                                        </div>

                                    </form>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3"></div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default FashionShowModelRegistration
