import React, { useEffect, useState } from 'react'

import Slider from 'react-slick';
import banner1 from "./Media/banner/banner1.jpg";
import banner2 from "./Media/banner/banner2.jpg";
import banner3 from "./Media/banner/banner3.jpg";
import banner4 from "./Media/banner/banner4.jpg";
import banner5 from "./Media/banner/banner5.jpg";


import logo from "./Media/logo.png";
import maydhruva_logo from "./Media/maydhruva_logo.png";
import heading_design_left from "./Media/heading_design_left.png";
import heading_design_right from "./Media/heading_design_right.png";

import services_decor from "./Media/services/services_decor.jpg";
import services_creatives from "./Media/services/services_creatives.jpg";
import services_food from "./Media/services/services_food.jpg";
import services_hospitality from "./Media/services/services_hospitality.jpg";
import services_invites from "./Media/services/services_invites.jpg";
import services_makeup from "./Media/services/services_makeup.jpg";
import services_photography from "./Media/services/services_photography.jpg";
import services_styling from "./Media/services/services_styling.jpg";
import services_transport from "./Media/services/services_transport.jpg";
import services_wedding from "./Media/services/services_wedding.jpg";

import yash_and_radhika from "./Media/video_images/yash_and_radhika.jpg";
import shivanna_daughter from "./Media/video_images/shivanna_daughter.jpg";
import kumaraswamy_family from "./Media/video_images/kumaraswamy_family.jpg";
import zaheer_ahmed_daughter from "./Media/video_images/zaheer_ahmed_daughter.jpg";

import * as BsIcons from "react-icons/bs";

import photo_gallery_1 from "./Media/photo_gallery/photo_gallery_1.png";
import photo_gallery_2 from "./Media/photo_gallery/photo_gallery_2.png";
import photo_gallery_3 from "./Media/photo_gallery/photo_gallery_3.png";
import photo_gallery_4 from "./Media/photo_gallery/photo_gallery_4.png";
import photo_gallery_5 from "./Media/photo_gallery/photo_gallery_5.png";
import photo_gallery_6 from "./Media/photo_gallery/photo_gallery_6.png";
import photo_gallery_7 from "./Media/photo_gallery/photo_gallery_7.png";
import photo_gallery_8 from "./Media/photo_gallery/photo_gallery_8.png";
import photo_gallery_9 from "./Media/photo_gallery/photo_gallery_9.png";
import photo_gallery_10 from "./Media/photo_gallery/photo_gallery_10.png";
import photo_gallery_11 from "./Media/photo_gallery/photo_gallery_11.png";
import photo_gallery_12 from "./Media/photo_gallery/photo_gallery_12.png";
import photo_gallery_border from "./Media/photo_gallery/photo_gallery_border.png";

import vendor from "./Media/vendor_stall.jpg";
import sponsor from "./Media/sponsor.jpg";
import fashion_model from "./Media/fashion_model_1.png";
import fashion_designer from "./Media/fashion_designer.jpg";
// import samuhaka_vivah from "./Media/samuhaka_vivah.png";

import Axios from "axios";

const Home = () => {
    useEffect(() => {
        const checkPosition = () => {
            if (window.matchMedia('(max-width: 992px)').matches) {
                setServicesSliderSettings({
                    className: "center",
                    infinite: true,
                    slidesToShow: 2,
                    speed: 750,
                    rows: 2,
                    slidesPerRow: 1,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                });
            } else {
                setServicesSliderSettings({
                    className: "center",
                    infinite: true,
                    slidesToShow: 3,
                    speed: 750,
                    rows: 2,
                    slidesPerRow: 1,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                });
            }
        }

        checkPosition();
    }, [])

    const bannerSlidersettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,

    };

    const [servicesSliderSettings, setServicesSliderSettings] = useState({
        className: "center",
        infinite: true,
        slidesToShow: 3,
        speed: 750,
        rows: 2,
        slidesPerRow: 1,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
    });

    const OpenInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (<>
        <button style={
            { display: "none" }}
            id="btn_whatsapp_send_invitation"
            onClick={
                () => OpenInNewTab("https://api.whatsapp.com/send?phone=+919380359377&text=Hi%20I%20am%20interested%20in%20Wedding%20Expo%20By%20Dhruva.%20Please%20send%20official%20invitation.")}> Send Invitation in whatsapp </button>

        <section className="section_banner_slider"
            id="home">
            <Slider className="div_slider" {...bannerSlidersettings}>
                <div className="div_banner_slick_parent">
                    <div className="div_text_on_banner_image p-2">
                        <h1 className="text_on_banner_slider"> OFFICIAL WEDDING EXPO BY </h1>
                        <img src={logo} alt="official wedding expo" className="logo_on_slider" />
                        <button className="btn btn_dhruva btn_expo_know_more mt-3" onClick={() => OpenInNewTab("http://expobydhruva.weddingsbydhruva.com/")}>Know More</button>
                    </div>
                    <img src={banner1} className="img_banner imageForText" alt="" />
                </div>
                <div className="div_banner_slick_parent">
                    <div className="div_text_on_banner_image p-2">
                        <h1 className="text_on_banner_slider"> MULTIDESIGNER STORE BY </h1>
                        <img src={maydhruva_logo} alt="multidesigner store" className="logo_on_slider" />
                        <button className="btn btn_dhruva btn_expo_know_more mt-3" onClick={() => OpenInNewTab("http://maydhruva.weddingsbydhruva.com/")}>Know More</button>
                    </div>
                    <img src={banner2} className="img_banner imageForText" alt="" />
                </div>
                <div>
                    <img src={banner3} className="img_banner" alt="" />
                </div>
                <div>
                    <img src={banner4} className="img_banner" alt="" />
                </div>
                <div>
                    <img src={banner5} className="img_banner" alt="" />
                </div>
            </Slider>
        </section>

        <section className="section_our_services" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-12 div_heading">
                        <ul className="nav justify-content-center">
                            <li className="nav-item my-auto">
                                <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                            </li>
                            <li className="nav-item my-auto">
                                <h1 className="h_tag_heading"> Our Services </h1>
                            </li>
                            <li className="nav-item my-auto">
                                <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Slider {...servicesSliderSettings}>
                            <div>
                                <img src={services_decor} alt="services_decor" className="img_services" />
                                <p className="text-center p_tag_services">
                                    <b> DECOR </b></p>
                            </div>

                            <div>
                                <img src={services_wedding} alt="services_wedding" className="img_services" />
                                <p className="text-center p_tag_services">
                                    <b> WEDDING PLANNING </b></p>
                            </div>

                            <div>
                                <img src={services_makeup} alt="services_makeup" className="img_services" />
                                <p className="text-center p_tag_services">
                                    <b> MAKE UP </b></p>
                            </div>

                            <div>
                                <img src={services_transport} alt="services_transport" className="img_services" />
                                <p className="text-center p_tag_services">
                                    <b> TRANSPORT & LOGISTICS </b></p>
                            </div>

                            <div>
                                <img src={services_invites} alt="services_invites" className="img_services" />
                                <p className="text-center p_tag_services">
                                    <b> INVITES & RSVP </b></p>
                            </div>

                            <div>
                                <img src={services_photography} alt="services_photography" className="img_services" />
                                <p className="text-center p_tag_services">
                                    <b> PHOTOGRAPHY & FILMS </b></p>
                            </div>

                            <div>
                                <img src={services_styling} alt="services_styling" className="img_services" />
                                <p className="text-center p_tag_services">
                                    <b> STYLING & DESIGNERS </b></p>
                            </div>

                            <div>
                                <img src={services_food} alt="services_food" className="img_services" />
                                <p className="text-center p_tag_services">
                                    <b> FOOD & BEVERAGE </b></p>
                            </div>

                            <div>
                                <img src={services_hospitality} alt="services_hospitality" className="img_services" />
                                <p className="text-center p_tag_services">
                                    <b> HOSPITALITY </b></p>
                            </div>

                            <div>
                                <img src={services_creatives} alt="services_creatives" className="img_services" />
                                <p className="text-center p_tag_services">
                                    <b> CREATIVES & GIFTING </b></p>
                            </div>

                        </Slider>
                    </div>

                </div>
            </div>
        </section>

        <section className="section_destination_weddings" id="destination_weddings">

        </section>

        <section className="section_video_gallery" id="video_gallery">
            <div className="container">
                <div className="row">
                    <div className="col-12 div_heading">
                        <ul className="nav justify-content-center">
                            <li className="nav-item my-auto">
                                <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                            </li>
                            <li className="nav-item my-auto">
                                <h1 className="h_tag_heading"> Video Gallery </h1>
                            </li>
                            <li className="nav-item my-auto">
                                <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 div_video_gallery_col"
                        onClick={() => { OpenInNewTab("https://www.youtube.com/watch?v=hOPIYN7FM9I&feature=youtu.be"); }}>
                        <img src={yash_and_radhika} alt="yash_and_radhika" className="img_video_gallery" />
                        <p className="text-center p_tag_video_gallery"><b> CELEBRITY MEGA STARS YASH & RADHIKA PANDIT WEDDING </b></p>
                        <BsIcons.BsYoutube className="youtube_icon_center" />
                    </div>

                    <div className="col-md-6 div_video_gallery_col"
                        onClick={() => { OpenInNewTab("https://www.youtube.com/watch?v=sPKtZp10z6I"); }}>
                        <img src={shivanna_daughter} alt="shivanna_daughter" className="img_video_gallery" />
                        <p className="text-center p_tag_video_gallery"><b> NIRUPAMA & DILEEP | MEGA STAR SHIVANNA DAUGHTER WEDDING </b></p>
                        <BsIcons.BsYoutube className="youtube_icon_center" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 div_video_gallery_col"
                        onClick={() => { OpenInNewTab("https://www.youtube.com/watch?v=dvDEz-tHdMQ"); }}>
                        <img src={kumaraswamy_family} alt="kumaraswamy_family" className="img_video_gallery" />
                        <p className="text-center p_tag_video_gallery"><b> KARNATAKA CHIEF MINISTER HD KUMARASWAMY FAMILY </b></p>
                        <BsIcons.BsYoutube className="youtube_icon_center" />

                    </div>
                    <div className="col-md-6 div_video_gallery_col"
                        onClick={() => { OpenInNewTab("https://www.youtube.com/watch?v=5Ff53iFjMZQ&feature=youtu.be"); }}>
                        <img src={zaheer_ahmed_daughter} alt="zaheer_ahmed_daughter" className="img_video_gallery" />
                        <p className="text-center p_tag_video_gallery"><b> MLA ZAHEER AHMED WEDDING </b></p>
                        <BsIcons.BsYoutube className="youtube_icon_center" />
                    </div>
                </div>
            </div>
        </section>

        <section className="section_photo_gallery"
            id="photo_gallery">
            <div className="container">
                <div className="row">
                    <div className="col-12 div_heading">
                        <ul className="nav justify-content-center">
                            <li className="nav-item my-auto">
                                <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                            </li>
                            <li className="nav-item my-auto">
                                <h1 className="h_tag_heading"> Photo Gallery </h1>
                            </li>
                            <li className="nav-item my-auto">
                                <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_1} alt="Bridal Lehenga" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Bridal Lehenga </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />
                        </div>

                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_2} alt="photo_gallery_2" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Mehnedi Designs </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />
                        </div>

                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_3} alt="photo_gallery_3" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Wedding Decor </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />
                        </div>

                    </div>

                    <div className="col-md-3">
                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_4} alt="photo_gallery_4" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Outfits </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />
                        </div>

                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_5} alt="photo_gallery_5" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Wedding Jewellery </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                        </div>

                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_6} alt="photo_gallery_6" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Wedding photoshots </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                        </div>

                    </div>
                    <div className="col-md-3">
                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_7} alt="photo_gallery_7" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Blouse Designs </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                        </div>

                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_8} alt="photo_gallery_8" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Wedding Makeup </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                        </div>

                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_9} alt="photo_gallery_9" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Pre Wedding Shots </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                        </div>

                    </div>
                    <div className="col-md-3">
                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_10} alt="photo_gallery_10" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Wedding Saree </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                        </div>

                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_11} alt="photo_gallery_11" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Wedding Hairstyles </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                        </div>

                        <div className="div_img_photo_gallery">
                            <img src={photo_gallery_12} alt="photo_gallery_12" className="img_photo_gallery" />
                            <p className="text-center p_tag_photo_gallery"> Groom Wedding Dresses </p>
                            <img src={photo_gallery_border} alt="photo_gallery_border" className="img_photo_gallery_border" />

                        </div>

                    </div>
                </div>
            </div>
        </section>

    </>
    )
}

export default Home