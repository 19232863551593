import React, { useState } from 'react'
import { Container, Modal, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import logo_with_border_design from "../Media/logo_with_border_design.png";
import logo_without_border_design from "../Media/logo_without_border_design.png";
import top_design from "../Media/top_design.png";
import logo from "../Media/logo.png";
import Axios from 'axios';
import * as BiIcons from "react-icons/bi";
import { useHistory } from 'react-router-dom';
import { contains } from 'jquery';

import * as FaIcons from "react-icons/fa"; 
import * as FiIcons from "react-icons/fi"; 

const WebsiteNavbar = () => {

    const history = useHistory();

    const [bookAppointmentModalShow, setBookAppointmentModalShow] = useState(false);

    const handleBookAppointmentModalClose = () => setBookAppointmentModalShow(false);
    const handleBookAppointmentModalShow = () => setBookAppointmentModalShow(true);

    const submitBookAppointment = async (e) => {
        e.preventDefault();

        const submitBookAppointmentData = {
            name: e.target.name.value,
            mobile: e.target.mobile.value,
            email: e.target.email.value
        }

        const submitBookAppointmentUrl = global.config.server_url + "addDataBookAppointment";

        console.log(submitBookAppointmentUrl, submitBookAppointmentData);

        Axios.post(submitBookAppointmentUrl, submitBookAppointmentData).then((response) => {
            if (response.data === true) {
                handleBookAppointmentModalClose();
                e.target.name.value = "";
                e.target.mobile.value = "";
                e.target.email.value = "";
            } else {
                alert("error in sending data")
            }
        }).catch(err => {
            console.log(err);
            alert("Error in sending data");
        });
    }

    const OpenInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }


    const [toggleValue, setToggleValue] = useState(true);

    const toggleClick = () => {
        setToggleValue(!toggleValue);
        var element = document.getElementById("logo_without_border_design");
        if (toggleValue) {
            element.style.height = "50px";
            element.style.top = "0px";
        } else {
            element.style.height = "100px";
            element.style.top = "";
        }
    }

    const navItemClick = () => {
        if (window.matchMedia('(max-width: 991px)').matches) {
            document.getElementById("toggle_button").click();
        }
    }

    const openPage = (page) => {
        console.log(page);

        history.push(page);
        navItemClick();
    }

    return (<>
        { /* MODAL for get invitation */}

        <Modal show={bookAppointmentModalShow}
            onHide={() => handleBookAppointmentModalClose()}>
            <form onSubmit={(e) => submitBookAppointment(e)}>

                <Modal.Header closeButton>
                    <img src={logo} alt="modal logo" className="modal_logo d-block mx-auto" />
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <input required type="text" className="form-control" name="name" placeholder="Enter Name" />
                    </div>
                    <div className="form-group my-3">
                        <input required type="text" className="form-control" name="mobile" placeholder="Enter Number" pattern="[6-9]{1}[0-9]{9}" />
                    </div>
                    <div className="form-group">
                        <input required type="email" className="form-control" name="email" placeholder="Enter Email" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <input type="button" value="Close" className="btn btn-secondary" onClick={() => handleBookAppointmentModalClose()} />
                    <input type="submit" value="Submit" className="btn btn_dhruva" />
                </Modal.Footer>
            </form>
        </Modal>

        <button className="btn btn_sticky" onClick={() => handleBookAppointmentModalShow()}>Book Appointment</button>

        <button className="btn btn_sticky_go_to_top" onClick={() => { window.scrollTo(0, 0) }} > <b><BiIcons.BiUpArrow /></b></button>

        <section className="section_sticky_btn">
                <button className="btn btn_sticky_call" id="btn_sticky_call" onClick={() => OpenInNewTab("Link", "tel:+91-9380359377")}><FiIcons.FiPhone /></button>
                <button className="btn btn_sticky_whatsapp" onClick={() => OpenInNewTab("Link", "https://api.whatsapp.com/send?phone=+91-9380359377&text=Hi%20I%20am%20interested%20in%20MAY%20Dhruva,%20Please%20call%20back%20and%20share%20the%20details.")}><FaIcons.FaWhatsapp /></button>
            </section>


        <section className="top_home" id="top_home">
            <img src={top_design} alt="top design" className="top_design" />
        </section>
        <section className="section_navbar">

            <Navbar expand="lg">
                <Container>
                    <img src={logo_without_border_design} className="logo_without_border_design d-lg-none" alt="logo with border design" id="logo_without_border_design" href={(window.location.pathname == "/") ? "#top_home" : ""} onClick={() => (window.location.pathname != "/") ? (openPage("/")) : (navItemClick())} />
                    <Navbar.Toggle aria-controls="basic-navbar-nav" id="toggle_button" onClick={() => toggleClick()} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <ul className="navbar-nav mx-auto ">
                            <li className="nav-item my-auto">
                                <a className="nav-link nav_link" aria-current="page" href={(window.location.pathname == "/") ? "#top_home" : ""} onClick={() => (window.location.pathname != "/") ? (openPage("/")) : (navItemClick())}> <b>Home</b></a>
                            </li>
                            <li className="nav-item my-auto">
                                <a className="nav-link nav_link" href={(window.location.pathname == "/") ? "#services" : ""} onClick={() => (window.location.pathname != "/") ? (openPage("/#services")) : (navItemClick())}><b>Services</b></a>
                            </li>

                            <li className="nav-item my-auto">
                                <a className="nav-link nav_link" href={(window.location.pathname == "/") ? "#video_gallery" : ""} onClick={() => (window.location.pathname != "/") ? (openPage("/#video_gallery")) : (navItemClick())}><b>Video Gallery</b></a>
                            </li>

                            <li className="nav-item my-auto">
                                <a className="nav-link nav_link" href={(window.location.pathname == "/") ? "#photo_gallery" : ""} onClick={() => (window.location.pathname != "/") ? (openPage("/#photo_gallery")) : (navItemClick())}><b>Photo Gallery</b></a>
                            </li>

                            <img src={logo_with_border_design} className="mx-auto logo_with_border_design " alt="logo with border design" href={(window.location.pathname == "/") ? "#top_home" : ""} onClick={() => (window.location.pathname != "/") ? (openPage("/")) : (navItemClick())} />


                            <li className="nav-item my-auto">
                                <a className="nav-link nav_link" onClick={() => OpenInNewTab("http://expobydhruva.weddingsbydhruva.com/")}><b>Wedding Expo by Dhruva</b></a>
                            </li>

                            <li className="nav-item my-auto">
                                <a className="nav-link nav_link" onClick={() => OpenInNewTab("http://maydhruva.weddingsbydhruva.com/")}><b>MAY Dhruva</b></a>
                            </li>
                        </ul>

                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </section>

    </>
    )
}

export default WebsiteNavbar