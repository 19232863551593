import React from 'react'
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from 'react-icons/md';
import logo from "../Media/logo.png";
import wedding_mansion_logo from "../Media/wedding_mansion_logo.jpg";

const WebsiteFooter = () => {
    const OpenInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <section className="section_footer ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <img src={logo} alt="footer logo" className="footer_logo d-block mx-auto" />
                            <h4 className="text-white text-center"> <b> WEDDINGS BY DHRUVA </b> </h4>
                            <div style={{ textAlign: "center" }}>
                                <div style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    2nd Floor, Ramanuja Complex, 5th Cross, <br />Malleshwaram, Bangalore - 560064.
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <div className="div_footer_header">
                                        <h4 className="text-white h_tag_footer_header">
                                            <b> REACH US AT </b></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <p className="text-white">
                                        <MdIcons.MdEmail />&nbsp;<span className="p_contact_link" onClick={() => OpenInNewTab("mailto:info@weddingsbydhruva.com")}> info @weddingsbydhruva.com </span></p>
                                    <p className="text-white">
                                        <MdIcons.MdWeb />&nbsp;<span className="p_contact_link" onClick={() => OpenInNewTab("mailto:info@weddingsbydhruva.com")}> http: //www.weddingsbydhruva.com/</span></p>
                                    <p className="text-white">
                                        <MdIcons.MdPhoneAndroid />&nbsp;<span className="p_contact_link" onClick={() => OpenInNewTab("tel:9844405540")}> 9844405540 </span></p>
                                    <p className="text-white">
                                        <MdIcons.MdCall />&nbsp;<span className="p_contact_link" onClick={() => OpenInNewTab("tel:08023315540")}> 08023315540 </span></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <div className="div_footer_header">
                                        <h4 className="text-white h_tag_footer_header">
                                            <b> FOLLOW US </b></h4>
                                    </div>
                                </div>
                            </div>
                            <div className="" style={{ textAlign: "center" }}>
                                <div className="" style={{ color: "#fff", display: "inline-block", textAlign: "left" }}>
                                    <ul className="footer_ul">
                                        <li className="footer_li"> <BsIcons.BsInstagram className="footer_instagram_icon" onClick={() => OpenInNewTab("https://www.instagram.com/iamdhruvakumar/")} /> <BsIcons.BsFacebook className="footer_facebook_icon" onClick={() => OpenInNewTab("https://www.facebook.com/IamDhruva-111356497990051/")} /> Dhruva </li>
                                        <li className="footer_li"> <BsIcons.BsInstagram className="footer_instagram_icon" onClick={() => OpenInNewTab("")} /> <BsIcons.BsFacebook className="footer_facebook_icon" onClick={() => OpenInNewTab("")} /> Weddings by Dhruva </li>
                                        <li className="footer_li"> <BsIcons.BsInstagram className="footer_instagram_icon" onClick={() => OpenInNewTab("https://www.instagram.com/weddingexpobydhruva/")} /> <BsIcons.BsFacebook className="footer_facebook_icon" onClick={() => OpenInNewTab("https://www.facebook.com/WeddingExpoByDhruva")} /> Wedding Expo by Dhruva </li>
                                        <li className="footer_li"> <BsIcons.BsInstagram className="footer_instagram_icon" onClick={() => OpenInNewTab("https://www.instagram.com/weddingstorebydhruva/")} /> <BsIcons.BsFacebook className="footer_facebook_icon" onClick={() => OpenInNewTab("https://www.facebook.com/Weddingstorebydhruva-107428835059898/")} /> Wedding Store by Dhruva </li>
                                        <li className="footer_li"> <BsIcons.BsInstagram className="footer_instagram_icon" onClick={() => OpenInNewTab("https://www.instagram.com/may_dhruva/")} /> <BsIcons.BsFacebook className="footer_facebook_icon" onClick={() => OpenInNewTab("https://www.facebook.com/Maydhruva-102786065532583/")} /> MAY Dhruva </li>
                                        <li className="footer_li"> <BsIcons.BsInstagram className="footer_instagram_icon" onClick={() => OpenInNewTab("https://www.instagram.com/stylebymaydhruva/")} /> <BsIcons.BsFacebook className="footer_facebook_icon" onClick={() => OpenInNewTab("https://www.facebook.com/Stylebymaydhruva-111866281277762/")} /> Style by MAY Dhruva </li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="w-100 mx-auto border border-top-light" />

                    <div className="row p-3">
                        <div className="col-4 text-center">
                            <span className="text-center text-white "> Copyright 2021. All Rights Reserved by Dhruva </span>
                        </div>
                        <div className="col-4 text-center">
                            <span className="text-center text-white "> Digital Marketing Partner: <span className="p_odm_link"
                                onClick={() => OpenInNewTab("https://www.orangedigitalmedia.in/")}> Orange Digital Media </span></span>
                        </div>
                        <div className="col-4 text-center">
                            <span className="text-center text-white ">
                                <span className="p_contact_link"> Privacy Policy </span> - <span className="p_contact_link">Disclaimer</span>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WebsiteFooter