import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Responsive.css';
import './Components/Config';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import WebsiteNavbar from './Components/WebsiteNavbar';
import Home from './Home';
import WebsiteFooter from './Components/WebsiteFooter';
import VendorRegistration from "./Registration/VendorRegistration";
import SponserRegistration from "./Registration/SponserRegistration";
import FashionShowModelRegistration from "./Registration/FashionShowModelRegistration";
import FashionShowDesignerRegistration from "./Registration/FashionShowDesignerRegistration";

function App() {
	return (
		<>
			<BrowserRouter>
				<Switch>
					<Route exact path="/">
						<WebsiteNavbar />
						<Home />
						<WebsiteFooter />
					</Route>
					<Route exact path="/VendorRegistration">
						<WebsiteNavbar />
						<VendorRegistration />
						<WebsiteFooter />
					</Route>
					<Route exact path="/SponserRegistration">
						<WebsiteNavbar />
						<SponserRegistration />
						<WebsiteFooter />
					</Route>
					<Route exact path="/FashionShowModelRegistration">
						<WebsiteNavbar />
						<FashionShowModelRegistration />
						<WebsiteFooter />
					</Route>
					<Route exact path="/FashionShowDesignerRegistration">
						<WebsiteNavbar />
						<FashionShowDesignerRegistration />
						<WebsiteFooter />
					</Route>

				</Switch>
			</BrowserRouter>

		</>
	);
}

export default App;
