import React from 'react'
import vendor from "../Media/vendor_stall.jpg";
import logo from "../Media/logo.png";
import heading_design_left from "../Media/heading_design_left.png";
import heading_design_right from "../Media/heading_design_right.png";
import { Card, FloatingLabel, Form } from 'react-bootstrap';
import Axios from "axios";

const VendorRegistration = () => {

    const submitVendorRegistration = async (e) => {
        // return;
        e.preventDefault();

        const submitVendorRegistrationData = {
            name: e.target.name.value,
            mobile: e.target.mobile.value,
            email: e.target.email.value,
            stall: e.target.stall.value
        }

        const submitVendorRegistrationUrl = global.config.server_url + "addDataVendorRegistration";

        console.log(submitVendorRegistrationUrl, submitVendorRegistrationData);

        Axios.post(submitVendorRegistrationUrl, submitVendorRegistrationData).then((response) => {
            if (response.data === true) {
                alert("You will receive Invitation through your mail");
                e.target.name.value = "";
                e.target.mobile.value = "";
                e.target.email.value = "";
                e.target.stall.value = "";
                document.getElementById("btn_whatsapp_send_invitation").click();
            } else {
                alert("error in sending data")
            }
        }).catch(err => {
            console.log(err);
            alert("Error in sending data");
        });
    }

    return (
        <div>
            <section className="section_vendorRegistration_banner">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="div_registration_banner_parent">
                                <div className="div_text_on_registration_banner_image">
                                    <h1 className="text_on_registration_banner_image"> BE THE VENDOR AT WEDDING EXPO BY  </h1>
                                    <img src={logo} alt="official wedding expo" className="logo_on_slider" />
                                </div>
                                <img src={vendor} className="img_registration_banner" alt="vendor registration" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_vendor_form">
                <div className="container">
                    <div className="row">
                        <div className="row">
                            <div className="col-12 div_heading">
                                <ul className="nav justify-content-center">
                                    <li className="nav-item my-auto">
                                        <img src={heading_design_left} alt="heading_design_left" className="heading_design_left" />
                                    </li>
                                    <li className="nav-item my-auto">
                                        <h1 className="h_tag_heading"> Fill Your Details </h1>
                                    </li>
                                    <li className="nav-item my-auto">
                                        <img src={heading_design_right} alt="heading_design_right" className="heading_design_right" />
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <Card className="card_form_registration">
                                <Card.Body className="py-0">
                                    <form onSubmit={(e) => submitVendorRegistration(e)}>
                                        <div className="form-group mt-2">
                                            {/* <input type="text" name="name" id="name" className="form-control input_registration" placeholder="Name" /> */}
                                            <FloatingLabel controlId="floatingInputGrid" label="Name">
                                                <Form.Control type="text" name="name" placeholder="Name" required="required" />
                                            </FloatingLabel>
                                        </div>
                                        <div className="form-group mt-2">
                                            {/* <input type="number" name="mobile" id="mobile" className="form-control input_registration" placeholder="Mobile" /> */}
                                            <FloatingLabel controlId="floatingInputGrid" label="Mobile">
                                                <Form.Control type="number" name="mobile" placeholder="Mobile" required="required" />
                                            </FloatingLabel>
                                        </div>
                                        <div className="form-group mt-2">
                                            {/* <input type="email" name="email" id="email" className="form-control input_registration" placeholder="Email" /> */}
                                            <FloatingLabel controlId="floatingInputGrid" label="Email">
                                                <Form.Control type="email" name="email" placeholder="Email" required="required" />
                                            </FloatingLabel>
                                        </div>

                                        <div className="form-group mt-2">
                                            <FloatingLabel controlId="floatingSelect" label="Select Stall">
                                                <Form.Select aria-label="SelectStall" name="stall">
                                                    <option value="Jewellery" selected>Jewellery</option>
                                                    <option value="Clothing Store">Clothing Store</option>
                                                    <option value="Invitation Cards">Invitation Cards</option>
                                                    <option value="Gifting">Gifting</option>
                                                    <option value="Startup Gifting">Startup Gifting</option>
                                                    <option value="Miscellaneous">Miscellaneous</option>
                                                    <option value="Live Makeup">Live Makeup</option>
                                                    <option value="Post Wedding">Post Wedding</option>
                                                    <option value="Restaurants">Restaurants</option>
                                                    <option value="Builders">Builders</option>
                                                    <option value="Removation and Painting">Removation and Painting</option>
                                                    <option value="Mattress / 1 Gram Gold">Mattress / 1 Gram Gold</option>
                                                    <option value="Silver Store and Gifting">Silver Store and Gifting</option>
                                                    <option value="Artificial Silver">Artificial Silver</option>
                                                    <option value="Car Rentals">Car Rentals</option>
                                                    <option value="Photo and Video">Photo and Video</option>
                                                    <option value="Album Making">Album Making</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </div>

                                        <div className="form-group mt-3">
                                            <button type="submit" className="btn btn_dhruva d-block mx-auto my-2">Register</button>
                                        </div>

                                    </form>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-3"></div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default VendorRegistration
